<template>
    <div class="page-wrapper" id="main">
      <v-card :disabled="saving || pkoSaved"
        v-bind:style="pkoSaved?'background:#c5c5c5' : ''">
        <v-card-title>ПКО по документу {{ localdoc.docnum }}</v-card-title>
        <v-card-text>
            <v-row>
              <v-col class="itemValue">
                Итого к оплате: {{ sum_opl }} 
              </v-col>             
            </v-row>  
            <v-row class="pa-ma-4" v-for="elem in doc.sums"
                :key="elem">
                -{{ elem.item_name }}: {{ elem.sum_opl }} 
            </v-row>
            <v-row>
              <v-col class="itemValue">
                Принято по факту от клиента:
              </v-col>             
            </v-row>  
            <v-row class=""
               v-for="sum_element in payments"
                :key="sum_element">
                <v-text-field type="number" pattern="[0-9]*" v-if = "!((localdoc.city_id!='56') && (sum_element.paymentType=='Эквайринг'))"
                  v-model = "sum_element.sum" :label="sum_element.paymentType" variant="outlined"></v-text-field>
            </v-row>


            <v-row>
              <v-col>
               <v-textarea v-model = "comment" label="Комментарий" variant="outlined"></v-textarea>
              </v-col>
            </v-row>  
        </v-card-text>
        <v-card-actions v-show="!pkoSaved">
          <v-btn
            class="mx-1"
            small
            outlined
            color="success"
            @click="saveDoc()"
            >Провести
          </v-btn>
          <v-btn
            class="mx-1"
            small
            @click="closeForm()"
            >Нет внесения денег
          </v-btn>
        </v-card-actions>

      </v-card>
      <v-progress-linear  v-show="(saving==true)"
      color="dark-blue" indeterminate></v-progress-linear>

    </div>
  </template>
  
  <script>
  import { mapGetters, mapState } from "vuex"

//https://github.com/MBoretto/telegram-bot-vue-wep-app/blob/master/src/App.vue
  export default {
    components: {  },
    props: {
      arendaid: String
    },
    data: function() {
      return {
        localdoc: {},
        comment: '',
        paymentType: -1,
        paymentTypes: ['Наличные','Эквайринг','Перевод на карту','QR'],
        payments: [{paymentType:'Наличные',sum:0},{paymentType:'QR',sum:0},{paymentType:'Эквайринг',sum:0},{paymentType:'Перевод на карту',sum:0}]
      }
    },
    computed: {
      ...mapGetters('doc',['doc','saving']),
      ...mapState('doc',['dataLoaded','isError','ErrorText','menu_visible','pkoSaved']),
      sum_opl: function(){
          return this.doc.sums.reduce((accum,item) => accum + Number(item.sum_opl), 0) 
           }
    },
    created: async function() {
        this.$store.commit('doc/setShowMenu', false)
        this.$store.commit('doc/setError', '')
        if (Number(this.doc.sum)==0)
          {
            //this.$store.commit('doc/setError', 'По документу ввод ПКО невозможен, т.к. нет долга') 
            //this.$store.commit('doc/setShowMenu', true)
            
            this.$store.commit('doc/setpkoSaved', true)
            this.$store.commit('doc/setMode', 'newrko')            
          }
        console.log(' PKO: created')
        Object.assign(this.localdoc, this.doc)
        this.localdoc.sums = JSON.parse(JSON.stringify(this.doc.sums))
    },
    methods: {
      async closeForm() {  
        console.log('form closed')   
        this.$store.commit('doc/setpkoSaved', true)
        this.$store.commit('doc/setMode', 'newrko')
      },

      async saveDoc() {

        const data = {
          id:      this.arendaid,
          comment: this.comment,
          paymentType: this.paymentType,
          sums: this.doc.sums,
          sumsbytype: this.payments,
          }
        try { 
          data.tg = this.TWA.initDataUnsafe
          data.user = this.TWA.initDataUnsafe.user.username
        } catch(err) { 
          console.log('no tg')
        }
        const response = await this.$store.dispatch('doc/savePKO', data) 
        console.log('form PKO saved',response.data) 
        //this.TWA.showAlert('Документ ПКО сохранен');
        this.$store.commit('doc/setpkoSaved', true)
        this.$store.commit('doc/setMode', 'newrko')
        //this.$store.commit('doc/setMode', 'docinfo')
      },
      }
    }
  
  </script>
  
  <style scoped>
/*
bg_color            .
secondary_bg_color  var(--tg-theme-secondary-bg-color)
link_color          var(--tg-theme-link-color).
*/
#main {
  background-color: var(--tg-theme-bg-color, white);
  color: var(--tg-theme-text-color, black);
  word-wrap: break-word;
}
b {
  color: var(--tg-theme-hint-color, black);
}
h3 {
  color: var(--tg-theme-text-color, black);
}
button22 {
  background-color: var(--tg-theme-button-color, #008CBA);
  border: 5px;
  color: var(--tg-theme-button-text-color, black);
  padding: 15px;
  margin: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
}
</style>