<template>
    <div class="page-wrapper" id="main">
      <v-card  v-if="dataLoaded==true" :disabled="saving || rkoSaved"
        v-bind:style="rkoSaved?'background:#c5c5c5' : ''">
        <v-card-title>РКО по аренде</v-card-title>

        <v-card-text>
            <v-row>
              <v-col class="sumauto">
                 Автоматически внесены расходы:
              </v-col>             
            </v-row>  
            <v-row class="sumauto ma-3"
                v-for="sum_element in docRKO.sums_auto"
                  :key="sum_element">
                    <div >{{ sum_element.comment }}: {{ sum_element.sum_opl }}</div>
            </v-row>  

            <v-row>
              <v-col class="itemValue">
                Итого доп. расходы: {{ sum_opl }} 
              </v-col>             
            </v-row>  
            <v-row class=""
               v-for="sum_element in docRKO.sums"
                :key="sum_element">
                <v-text-field type="number" pattern="[0-9]*"
                  v-model = "sum_element.sum_opl" :label="sum_element.item_name" variant="outlined"
                  max="sum_element.sum"
                  ></v-text-field>
            </v-row>

            <v-row>
              <v-col>
               <v-textarea v-model = "comment" label="Комментарий" variant="outlined"></v-textarea>
              </v-col>
            </v-row>  
        </v-card-text>
        <v-card-actions v-show="!rkoSaved">
          <v-btn
            class="mx-1"
            small
            outlined
            color="success"
            @click="saveDoc()"
            >Сохранить
          </v-btn>
          <v-btn
            class="mx-1"
            small
            @click="closeForm()"
            >Отмена
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-progress-linear  v-show="(saving==true)"
        color="dark-blue" indeterminate></v-progress-linear> 

    </div>
  </template>
  
  <script>
  //   :rules= "[rules.max(sum_element.sum,sum_element.sum_opl)]"
  
  import ArendaService from '@/api/arenda-service'
 import { mapGetters, mapState } from "vuex"

//https://github.com/MBoretto/telegram-bot-vue-wep-app/blob/master/src/App.vue
  export default {
    components: {  },
    props: {
      arendaid: String,
      rkotype: String
    },
    data: function() {
      return {
        docRKO: {},
        comment: '',
        rules: { max(maxvalue,value) { return (value <= maxvalue) && maxvalue !=0 || 'Максимальная сумма ' + maxvalue }
        }
      }
    },
    computed: {
      ...mapGetters('doc',['doc','saving']),
      ...mapState('doc',['dataLoaded','menu_visible','rkoSaved']),
      sum_opl: function(){
          if (this.docRKO.sums)
            return this.docRKO.sums.reduce((accum,item) => accum + Number(item.sum_opl), 0) 
          else return 0
           }
    },
    created: async function() {

        var userid = ''
        try { 
           userid = this.TWA.initDataUnsafe.user.id
        } catch(err) {
          userid = '128283566'
        }
        const response = await ArendaService.newRKO(this.arendaid, userid)
        this.docRKO = response
        if (response["needEdit"] == false)
          {          
            this.$store.commit('doc/setrkoSaved', true)
            this.$store.commit('doc/setMode', 'docinfo') 
            return
          } 
        console.log(' RKO: created ',JSON.stringify(response))
        this.$store.commit('doc/setShowMenu', false)
        this.$store.commit('doc/dataLoaded', true)
    },
    mounted: function() {
      this.obj = {}
      console.log(' RKO: mounted')
    },
    methods: {
      async closeForm() {  
        console.log('form closed')   
        this.$store.commit('doc/setMode', 'docinfo')
        //this.TWA.close()
      },
      toggleType() {
        console.log('toggle')
      },
      checkMaxSum(value) {
        console.log('check '+value)
        if (value>=1000)
          return 'максимум 1000'
        
        return true
      },
      async saveDoc() {

        if (this.docRKO.sums.findIndex( e => e.sum != 0 && e.sum_opl>e.sum) >=0 )
          { console.log('превышение суммы ')
            this.TWA.showAlert('Превышение суммы. Невозможно создать документ.')
            return
          }
        const data = {
          id:      this.arendaid,
          comment: this.comment,
          sums: this.docRKO.sums.map((item) => ( {sum_opl: Number(item.sum_opl), item_code: item.item_code})),
          }
        try { 
          data.tg = this.TWA.initDataUnsafe
          data.user = this.TWA.initDataUnsafe.user.username
        } catch(err) { 
          console.log('no tg')
        }
        this.$store.commit('doc/setSaving', true)
        const response = await this.$store.dispatch('doc/saveRKO', data) 
 
//        const response = await ArendaService.saveNewRKO(this.arendaid, data)
        console.log(JSON.stringify(response) )
        console.log('form RKO saved') 
        //this.TWA.showAlert('Документ РКО сохранен');
        this.$store.commit('doc/setSaved', true)
        this.$store.commit('doc/setrkoSaved', true)
        this.$store.commit('doc/setMode', 'docinfo')
      },
      }
    }
  
  </script>
  
  <style scoped>
/*
bg_color            .
secondary_bg_color  var(--tg-theme-secondary-bg-color)
link_color          var(--tg-theme-link-color).
*/
#main {
  background-color: var(--tg-theme-bg-color, white);
  color: var(--tg-theme-text-color, black);
  word-wrap: break-word;
}
b {
  color: var(--tg-theme-hint-color, black);
}
h3 {
  color: var(--tg-theme-text-color, black);
}
.sumauto {
  font-size: 15px;
  color: #280380; 
  font-weight: 600;
}
button22 {
  background-color: var(--tg-theme-button-color, #008CBA);
  border: 5px;
  color: var(--tg-theme-button-text-color, black);
  padding: 15px;
  margin: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
}
</style>