<template>
    <div class="page-wrapper" id="main">
      <v-card  v-if="dataLoaded==true" :disabled="saving">
        <v-app-bar rounded scroll-behavior="elevate">
          <v-btn v-if="localdoc.status == 'Новый' && localdoc.operation_status == 1"
            class="mx-1"  small  outlined color="success"  @click="saveDoc(true)"
            >Забронировать
          </v-btn>
          <v-btn v-if="(localdoc.status == 'К Выдаче') || (localdoc.status == 'Новый' && localdoc.operation_status == 0)"
              class="mx-1"  small  outlined color="success"  @click="saveDoc(true)"
              >Выдать
          </v-btn>
          <v-btn v-if="localdoc.status == 'Активно'"
            class="mx-1"  small  outlined color="success" @click="saveDoc(true)"
            >Продлить
          </v-btn>
          <v-btn v-if="localdoc.status == 'Активно'"
            class="mx-1"  small outlined  color="red" @click="saveDoc(false)"
            >Завершить
          </v-btn>
          <v-btn
            class="mx-1 right"   small @click="closeForm()"
            >Закрыть
          </v-btn>
        </v-app-bar>   
        <v-card-title>Внутр. использование {{ localdoc.docnum }} ({{ localdoc.status }})</v-card-title>

        <v-card-text>
          <v-row v-if="localdoc.status == 'Новый'">
              <div class="pa-4">
                <v-chip-group
                  selected-class="text-primary" v-model="localdoc.operation_status">
                  <v-chip
                    v-for="(status) in statuses"
                    :key="status"
                  >  {{ status }} </v-chip>
                </v-chip-group>
              </div>
          </v-row>  
          <v-row v-if="isNew == false" class="ma-5">
              <p class="text-h6 text-primary">{{ localdoc.ts }} </p>
          </v-row>
          <v-row v-if="isNew == true">
              <v-text-field type="number" pattern="[0-9]*"
                  v-model = "filterCar" label="Номер ТС" variant="outlined"
              ></v-text-field>
          </v-row>
          <v-row v-if="showCarResults && isNew == true">
            <div class="pa-4">
              <div v-if="filteredCars.length==0">Не найден автомобиль с номером {{ filterCar }}</div>
              <v-chip-group
                selected-class="text-primary"
                v-model="selectedCar"
                column
              >
                <v-chip
                  v-for="(car,index) in filteredCars"
                  :key="index"
                >
                  {{ car.name }}
                </v-chip>
              </v-chip-group>
            </div>
          </v-row>  

          <v-row >
            <div class="pa-4" >
              <v-chip-group v-if="false"
                selected-class="text-primary"
                v-model="localdoc.operation_type">
                <v-chip
                  v-for="(optype,index) in operation_types"
                  :key="index" >
                  {{ optype }}
                </v-chip>
              </v-chip-group>
              <v-select v-model = "localdoc.operation_type"
                  label = "Вид операции"
                  :disabled="!isNew"
                  :items="operation_types"
                  item-title="name"
                  item-value="id">
                </v-select>
            </div>
          </v-row>  
          <v-row v-if="localdoc.operation_type == 6">
            <v-col cols="6">
              <v-text-field type="number" pattern="[0-9]*"
                  v-model = "localdoc.rel_doc" label="№ аренды" variant="outlined"
                  @input="searchDocs"
                  ></v-text-field>
            </v-col>
            <v-col cols="6">
              <p> {{ docinfo }}</p>
            </v-col>
          </v-row>

          <v-row v-if="OutActionEnabled">
            <v-col cols="6">
              <v-text-field type="date" 
                v-model = "localdoc.date_out" label="Дата выдачи" variant="outlined"
                locale = "ru-ru"
                ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field type="time"
                v-model = "localdoc.time_out" label="Время выдачи" variant="outlined"
                ></v-text-field>
              </v-col>
            <v-col cols="2">
              <v-btn
                class="mx-1" :disabled="localdoc.status == 'Активно'" small icon="mdi-clock" @click="setCurrentTime('out',0)">
              </v-btn>
            </v-col>
          </v-row>  
          <v-row v-if="!OutActionEnabled" class="ma-5">
            <p class="text-h6"> Выдано: {{ localdoc.date_out }} {{ localdoc.time_out }}  </p>
          </v-row>  

          <v-row v-if="localdoc.operation_status != 1 && OutActionEnabled">
              <v-text-field type="number" pattern="[0-9]*" 
                v-model = "localdoc.odo_out" label="Одометр выдача (км)" variant="outlined"
                min="odo_min"
                ></v-text-field>
          </v-row>  
          <v-row v-if="localdoc.operation_status != 1 && OutActionEnabled">
              <v-text-field type="number" pattern="[0-9]*" 
                v-model = "localdoc.gsm_out" label="Остаток топлива выдача (км)" variant="outlined"
                ></v-text-field>
          </v-row>  
          <v-row v-if="InActionEnabled">
            <v-col cols="6">
              <v-text-field type="date" 
                v-model = "localdoc.date_in" label="Дата возврата" variant="outlined"
                locale = "ru-ru"
                ></v-text-field>
            </v-col>            
            <v-col cols="4">
              <v-text-field type="time" 
                v-model = "localdoc.time_in" label="Время возврата" variant="outlined"
                ></v-text-field>
              </v-col>
            <v-col cols="2">
              <v-btn
                class="mx-1" small icon="mdi-clock" @click="setCurrentTime('in',0)">
              </v-btn>
            </v-col>
          </v-row>  
          <v-row v-if="!InActionEnabled" class="ma-5">
            <p class="text-h6"> Возврат: {{ localdoc.date_in }} {{ localdoc.time_in }}  </p>
          </v-row>            
          <v-row v-if="InActionEnabled && localdoc.status=='Активно'" >
              <v-text-field type="number" pattern="[0-9]*"
                v-model = "localdoc.odo_in" label="Одометр возврат(км)" variant="outlined"
                min="odo_min"
                ></v-text-field>
          </v-row>  
          <v-row v-if="InActionEnabled && localdoc.status=='Активно'">
              <v-text-field type="number" pattern="[0-9]*"
                v-model = "localdoc.gsm_in" label="Остаток топлива возврат(км)" variant="outlined"
                ></v-text-field>
          </v-row>  

          <v-row class="my-5">
              <p class="text-h7">Ответственный: {{ localdoc.sotr }} </p>
          </v-row>  
          <v-row>
              <v-textarea v-model = "localdoc.description" label="Цель" variant="outlined"></v-textarea>
          </v-row>  
        </v-card-text>
        <v-card-actions v-if="false">
   
        <v-btn v-if="localdoc.status == 'Новый' && localdoc.operation_status == 1"
          class="mx-1"
          small
          outlined
          color="success"
          @click="saveDoc(true)"
          >Забронировать
        </v-btn>
        <v-btn v-if="(localdoc.status == 'К Выдаче') || (localdoc.status == 'Новый' && localdoc.operation_status == 0)"
            class="mx-1"
            small
            outlined
            color="success"
            @click="saveDoc(true)"
            >Выдать
        </v-btn>
        <v-btn v-if="localdoc.status == 'Активно'"
          class="mx-1"
          small
          outlined
          color="success"
          @click="saveDoc(true)"
          >Продлить
        </v-btn>
        <v-btn v-if="localdoc.status == 'Активно'"
          class="mx-1"
          small
          outlined
          color="red"
          @click="saveDoc(false)"
          >Завершить
        </v-btn>
      </v-card-actions>

      </v-card>

      <v-row style="height: 250px;">
      </v-row> 
    </div>

  </template>
  
  <script>
  import ArendaService from '@/api/arenda-service'
  import { mapGetters } from "vuex"
  import { debounce } from 'lodash'

  
  export default {
    components: {  },
    props: {
      
    },
    data: function() {
      return {
        comment: '',
        localdoc: {},
        carlist: [],
        filterCar: '',
        docinfo: '',
        selectedCar: 0,
        operation_types: [{name:'Перегон', id:0},
                          {name:'Мойка', id:1},
                          {name:'Доставка', id:2},
                          {name:'ТО плановое', id:5},
                          {name:'Повреждение клиентами', id:6},
                          {name:'Ремонт/Сервис', id:3},
                          {name:'Прочее', id:4}],
        statuses: ['Бронь сейчас','Бронь на будущее']
      }
    },
    computed: {
      ...mapGetters('doc',['saving','url_vars','current_doc','current_doc_id','dataLoaded','tg','cars']),
      isNew(){
        return this.current_doc_id == 'new'
      },
      InActionEnabled(){
        return (this.localdoc.status == 'Активно' || this.localdoc.status == 'Новый' || this.localdoc.status == 'К Выдаче')
      },
      OutActionEnabled(){
        return (this.localdoc.status == 'Новый' || this.localdoc.status == 'К Выдаче')
      },
      filteredCars() {
        if (this.filterCar.length < 3) {
          return [];
        }
        const filter = this.filterCar.toLowerCase();
        return this.cars.filter(car => car.code.toLowerCase().indexOf(filter) !== -1);
      },
      showCarResults() {
        return this.filterCar.length >= 3;
      },
    },
    created: async function() {
      console.log('doc created 1',JSON.stringify(this.current_doc))
      await this.$store.dispatch('doc/loadCars', true)
      this.carlist = this.cars
      
      if (this.current_doc_id == 'new') {
        this.localdoc = { id: '',
          description: '',
          operation_status: 1, //0-сейчас, 1-позже
          ts: '',
          operation_type: 0,
          ts_id: '',
          date_out: '',
          time_out: '',
          date_in: '',
          time_in: '',
          status: 'Новый',
          docnum: '',
          rel_doc: '',
        }
        //this.setCurrentTime('out', 0)
        //this.setCurrentTime('in', 1)
      } else
        Object.assign(this.localdoc, this.current_doc)
    },
    mounted: async function() {
        console.log('doc mounted 1',this.current_doc_id)
    },

    methods: {
      async closeForm() {  
        console.log('form closed')  
        this.$store.commit('doc/setCurrentDocID', '')  
      },

      async saveDoc(needContinue) {

        if (this.localdoc.status == 'Новый'){
          if  (this.filteredCars.length==0 || (this.filteredCars.length < this.selectedCar))
            { console.log('не выбран автомобиль ')
              this.TWA.showAlert('Не выбран автомобиль')
              return
            }
          if (this.filteredCars.length==1) {
            this.selectedCar = 0
          }            
          console.log('save for car', this.filteredCars.length, this.selectedCar, this.filteredCars[this.selectedCar],(this.operationType==0), (this.filteredCars.length < this.selectedCar))
        }
        if (this.localdoc.description === '') {
            console.log('не заполнена цель поездкии ')
              this.TWA.showAlert('Не заполнена цель поездкии')
              return 
        }
        if (((this.localdoc.status == 'Новый') && (this.localdoc.operation_status == 0))||(this.localdoc.status == 'К Выдаче')) {
          if (!this.localdoc.odo_out) {
            console.log('не заполнен одометр выдачи ')
            this.TWA.showAlert('Не заполнен одометр выдачи')
            return
          }
          if (!this.localdoc.gsm_out) {
            console.log('не заполнен топливо выдачи ')
            this.TWA.showAlert('Не заполнено топливо выдачи')
            return
          }
        }
        else if (!needContinue) {
          // если завершаем 
          if (!this.localdoc.odo_in) {
            console.log('не заполнен одометр возврата ')
            this.TWA.showAlert('Не заполнен одометр возврата')
            return
          }
          if (!this.localdoc.gsm_in) {
            console.log('не заполнен топливо возврата ')
            this.TWA.showAlert('Не заполнено топливо возврата')
            return
          }

        }
        if (this.localdoc.time_in === '') {
            console.log('не заполнено время возврата ')
            this.TWA.showAlert('Не заполнено время возврата')
            return 
        }
        
        
        var Date_in = new Date(this.localdoc.date_in + ' ' + this.localdoc.time_in)
        console.log('date out ', Date_in)
        const data = {
            id: this.current_doc_id,
            needContinue: needContinue,
            newcar: this.filteredCars[this.selectedCar],
            ...this.localdoc
          }
        try { 
          data.tg = this.TWA.initDataUnsafe
          data.user = this.TWA.initDataUnsafe.user.username
          data.userid = this.TWA.initDataUnsafe.user.id
        } catch(err) { 
          console.log('no tg')
          data.tg = {user:{id:'128283566'} }
          data.user = 'olegkrt'
          data.userid = '128283566'
        }
  
        console.log('save',JSON.stringify(data) )
        this.$store.commit('doc/setSaving', true) 
        const response = await ArendaService.saveInnerDoc(this.current_doc_id,data)
        console.log(JSON.stringify(response) )
        if (response.data.result == false) {
          this.$store.commit('doc/setError', 'Ошибка при сохранении документа') 
          this.$store.commit('doc/setSaved', false) 
          return  
        }

        this.$store.commit('doc/setSaved', true) 
        this.$store.commit('doc/setCurrentDocID', '')
        this.TWA.showAlert('Документ сохранен')
        this.$store.commit('doc/dataLoaded', false)
        await this.$store.dispatch('doc/loadInnerDocs', {})          
    
      },
      setCurrentTime(direction, shift){
        
        const now = new Date()
        now.setTime(now.getTime() + (shift)*3600*1000)
        console.log('date now 1',now)
        let minutes = Math.round(now.getMinutes() / 5) * 5;
        minutes = (minutes==60) ? 0 : minutes
        now.setMinutes(minutes);
        const cur_time = `${('0' +now.getHours()).slice(-2)}:${('0' + minutes).slice(-2)}`
        const formattedDate = ''+ now.getFullYear() + '-' + String(now.getMonth() + 1).padStart(2, '0') + '-' +String(now.getDate()).padStart(2, '0')
        console.log('date now 2',formattedDate)
        if (direction=='out'){
          this.localdoc.time_out = cur_time
          this.localdoc.date_out = formattedDate
        } else {
          this.localdoc.time_in = cur_time
          this.localdoc.date_in = formattedDate
        }
      },

      searchDocs: debounce(function() {
         if (this.localdoc.rel_doc.length >= 5) {
           console.log(`Search for ${this.localdoc.rel_doc}`)
           this.loadDocinfo()
         } else {this.docinfo = '---'}
       },500), // время задержки в миллисекундах
       
      async loadDocinfo() {
          var payload = {operationtype:'transfer'}
          try { 
            payload.tg = this.TWA.initDataUnsafe
            payload.user = this.TWA.initDataUnsafe.user.username
            payload.userid = this.TWA.initDataUnsafe.user.id
          } catch(err) { 
            console.log('no tg')
            payload.user = 'notg'
          }
          const response = await ArendaService.docinfo(this.localdoc.rel_doc, payload)
          console.log('result '+response.result)
           if (!response.id) {
             this.docinfo = 'не найден документ '+this.localdoc.rel_doc
           } else {
             this.docinfo = response.docinfo
           }

       }

      }
    }
  
  </script>
  
  <style scoped>
/*
bg_color            .
secondary_bg_color  var(--tg-theme-secondary-bg-color)
link_color          var(--tg-theme-link-color).
*/
#main {
  background-color: var(--tg-theme-bg-color, white);
  color: var(--tg-theme-text-color, black);
  word-wrap: break-word;
}
b {
  color: var(--tg-theme-hint-color, black);
}
h3 {
  color: var(--tg-theme-text-color, black);
}
button22 {
  background-color: var(--tg-theme-button-color, #008CBA);
  border: 5px;
  color: var(--tg-theme-button-text-color, black);
  padding: 15px;
  margin: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
}
</style>