<template>
    <div class="page-wrapper" id="discounts" :disabled="discountsSaved || !dataLoaded"
      v-bind:style="discountsSaved?'background:#c5c5c5' : ''">
        <v-row class="ma-3">Скидки по документу {{ localdoc.docnum }}</v-row>
        <v-row class="ma-3"
               v-for="sum_element in localsums"
                :key="sum_element" >
                <v-col cols="6">
                  <v-text-field type="number" pattern="[0-9]*" readonly
                    v-model = "sum_element.sum_nom" :label="sum_element.item_name" variant="outlined"></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field type="number" pattern="[0-9]*"
                    v-model = "sum_element.sum_discount" label="Скидка" variant="outlined"></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field type="number" pattern="[0-9]*" readonly
                    v-model = "sum_element.sum" label="Сумма" variant="outlined"></v-text-field>
                </v-col>
        </v-row>
        <v-row class="ma-3" v-show="!discountsSaved">
            <v-btn
            class="mx-1"
            small
            outlined
            color="success"
            @click="saveDoc()"
            >Установить скидки
          </v-btn>
          <v-btn
            class="mx-1"
            small
            @click="closeForm()"
            >Нет скидок
          </v-btn>
        </v-row>  
        <v-progress-linear  v-show="(saving==true)"
          color="dark-blue" indeterminate></v-progress-linear>             

      <v-card>
        <v-card-actions>
        </v-card-actions>
        <v-card-text>
        </v-card-text>

      </v-card>
  

    </div>
  </template>
  
  <script>
  import { mapGetters, mapState } from "vuex"

//https://github.com/MBoretto/telegram-bot-vue-wep-app/blob/master/src/App.vue
  export default {
    components: {  },
    props: {
      arendaid: String
    },
    data: function() {
      return {
        localdoc: {},
        localsums: []
      }
    },
    computed: {
      ...mapGetters('doc',['doc','saving']),
      ...mapState('doc',['dataLoaded','isError','ErrorText','menu_visible','discountsSaved']),
    },
    created: async function() {
        this.$store.commit('doc/setShowMenu', false)

        console.log(' discounts: created')
        Object.assign(this.localdoc, this.doc)
        this.localsums = JSON.parse(JSON.stringify(this.doc.sums.filter(item => item.discount_enabled)))
        this.localsums.forEach((item, index) => {
           //this.$set(item, "sum", item.sum_nom - item.sum_discount);
            this.$watch(
                () => item.sum_discount,
                () => {
                    this.calculateTotal(index)
                }
            )
            })

    },
    methods: {
      calculateTotal(index) {
            this.localsums[index].sum = this.localsums[index].sum_nom - this.localsums[index].sum_discount;
        },

      async closeForm() {  
        console.log('form discount closed')   
        this.$store.commit('doc/setDiscountsSaved', true)
        this.$store.commit('doc/setMode', 'newpko')
      },

      async saveDoc() {
        const data = {
          id:   this.arendaid,
          sums: this.localsums.map((item) => ( {sum_discount: Number(item.sum_discount), 
                                                    item_code: item.item_code,
                                                    sku_code: item.sku_code})),
          }
        try { 
          data.tg = this.TWA.initDataUnsafe
          data.user = this.TWA.initDataUnsafe.user.username
        } catch(err) { 
          console.log('no tg')
        }
        const response = await this.$store.dispatch('doc/saveDiscounts', data) 
        console.log('form discount saved',response.data) 
        this.$store.commit('doc/setDiscountsSaved', true)
        this.$store.commit('doc/setMode', 'newpko')

        //this.TWA.showAlert('Документ Аренда сохранен');
      },
      }
    }
  
  </script>
  
  <style scoped>
/*
bg_color            .
secondary_bg_color  var(--tg-theme-secondary-bg-color)
link_color          var(--tg-theme-link-color).
*/
#main {
  background-color: var(--tg-theme-bg-color, white);
  color: var(--tg-theme-text-color, black);
  word-wrap: break-word;
}
b {
  color: var(--tg-theme-hint-color, black);
}
h3 {
  color: var(--tg-theme-text-color, black);
}
button22 {
  background-color: var(--tg-theme-button-color, #008CBA);
  border: 5px;
  color: var(--tg-theme-button-text-color, black);
  padding: 15px;
  margin: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
}
</style>