<template>
    <innerDoc v-if="current_doc_id != ''" ></innerDoc>
    <div class="page-wrapper" id="main" 
         v-show="listvisible">
        <v-row class="ma-3">
            <v-btn
                class="mx-1"
                small
                @click="newDoc()"
                >Новый документ
            </v-btn>
            <v-btn
                class="mx-1"
                small
                @click="closeForm()"
                >Закрыть
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
                class="mx-1"
                small icon="mdi-update"
                @click="updateList()"
                >
          </v-btn>          
          <v-icon></v-icon>
        </v-row>
        <v-row class = "ma-3">
            <v-switch
                v-model="showCompleted"
                color="primary"
                hide-details
                inset
                label="Показывать завершенные"
            ></v-switch>
        </v-row>
        <v-row class = "scrollable-content ma-3">
            <v-list select-strategy="classic"
                lines="three"
            >
            <v-list-item
                v-for="item in filteredDocs"
                :key="item.docnum"
                item-props
                lines="three"
                
                :subtitle="item.description"
                @click="openDocForm(item.id)"
            >
            <template v-slot:prepend>
              <v-avatar :color="item.color">
                  <v-icon color="white">{{ item.icon }}</v-icon>
              </v-avatar>
            </template>
                <template v-slot:title>
                    <v-row no-gutters>
                        <v-col cols="12">
                            <div align="left" class="text-primary">{{ item.ts }} </div>
                            <div class="text-subtitle-1"> период: {{ item.period }} </div>
                            <div align="left" class="text-subtitle-3">{{ item.docnum }}-{{ item.status }} </div>
                            <div> ответственный: {{ item.sotr }} </div>
                        </v-col>
                    </v-row>
                </template>
            </v-list-item>
            </v-list>
        </v-row>

    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex"
  import innerDoc from '@/views/form_InnerDoc.vue'

  export default {
    components: {innerDoc},
    data: function() {
      return {
        showCompleted: false
     }
    },
    computed: {
      ...mapGetters('doc',['dataLoaded','url_vars','current_doc','current_doc_id','tg','innerDocs','arendaid']),
      dataLoading(){
        return this.dataLoaded == false
      },
      filteredDocs() {
        return this.innerDocs.filter(item => this.showCompleted || item.status === 'Активно' || item.status === 'К Выдаче')
      },
      listvisible(){
        return this.current_doc_id === ''
      }
    },
    created: async function() {
        await this.loadInnerDocs()
   },
    methods: {
      async loadInnerDocs() {
        this.$store.commit('doc/dataLoaded', false)
        await this.$store.dispatch('doc/loadInnerDocs', {})
        if (this.arendaid) {
          this.$store.commit('doc/setCurrentDocID', this.arendaid) 
        }   
      },
      openDocForm(id) {
         // Открываем форму передавая id запроса
         console.log('doc ',id)
         this.$store.commit('doc/setCurrentDocID', id) 

      },
      newDoc() {
         // Открываем форму передавая id запроса
         this.$store.commit('doc/setCurrentDocID', 'new') 
      },
      
      async updateList() {  
        await this.loadInnerDocs()
      },
      async closeForm() {  
        this.TWA.close()
      },

      formatDate(date) {
        const day = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()
        return `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year}`
        }

      }
    }
  
  </script>
  
<style>
.scrollable-content {
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
}
tr {
    white-space: pre-wrap;
}


</style>
