
import doc from './StoreDoc'

import { createStore } from 'vuex'


let debug = process.env.NODE_ENV !== 'production'

let store = createStore({
  modules: {
    doc: doc
  },
  strict: debug
})

//Vue.prototype.$store = store

export default store
