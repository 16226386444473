<template>
    <div class="page-wrapper" id="main">
      <v-card  v-if="dataLoaded==true" :disabled="saving">
        <v-card-title>РКО расходы</v-card-title>
        <v-card-text>
          <v-row class="ma-1" v-if="false">
            <v-btn-toggle
              v-model="operationType"
              rounded="10" mandatory
              color="deep-purple-accent-3"
            >
              <v-btn >
                На ТС
              </v-btn>
              <v-btn >
                Прочие
              </v-btn>
              <v-btn>
                Трансфер
              </v-btn>
            </v-btn-toggle>
          </v-row>
          <v-row v-if="operationType==0">
            <v-text-field type="number" pattern="[0-9]*"
                  v-model = "filterCar" label="Номер ТС" variant="outlined"
                  ></v-text-field>
          </v-row>
          <v-row v-if="showCarResults && operationType==0">
            <div class="pa-4">
              <div v-if="filteredCars.length==0">Не найден автомобиль с номером {{ filterCar }}</div>
              <v-chip-group
                selected-class="text-primary"
                v-model="selectedCar"
                column
              >
                <v-chip
                  v-for="(car,index) in filteredCars"
                  :key="index"
                >
                  {{ car.name }}
                </v-chip>
              </v-chip-group>
            </div>
          </v-row>  
          <v-row v-if="operationType==2">
            <v-col cols="6">
              <v-text-field type="number" pattern="[0-9]*"
                  v-model = "docNum" label="Номер документа" variant="outlined"
                  @input="searchDocs"
                  ></v-text-field>
            </v-col>
            <v-col cols="6">
              <p> {{ docinfo }}</p>
            </v-col>
          </v-row>
          <v-row justify="end">
            <v-col class="itemValue" cols="9">
              <p class="font-weight-bold text-h6">Итого расходы: {{ sum_opl }} </p>
            </v-col>   
            <v-col cols="3" v-show="false"> <v-btn @click="addRow" color="primary" outlined :disabled="saving">+</v-btn>
            </v-col>          
          </v-row>  

            <v-row class=""
               v-for="(sum_element,index) in sums"
                :key="sum_element">
              <v-col cols="12">
              <v-row>
                <v-col cols="11">
                  <v-select v-model = "sum_element.dds"
                  label = "Статья расходов"
                  :items="filteredDDS"
                  item-title="name"
                  item-value="id">
                </v-select>
              </v-col>
              </v-row>
              <v-row>
                <v-col cols="7"> 
                  <v-text-field label="Содержание"
                    v-model = "sum_element.comment" variant="outlined"
                    ></v-text-field>
                </v-col>
                <v-col cols="4" class="px-0">
                  <v-text-field type="number" pattern="[0-9]*" label="Сумма"
                    v-model = "sum_element.sum" variant="outlined"
                    ></v-text-field>
                </v-col>
                <v-col cols="1" class="px-0">
                  <v-btn class="mx-0" @click="removeRow(index)" color="error"
                    icon="mdi-minus" size="small">X</v-btn>
                </v-col>
              </v-row>
              </v-col>
            </v-row>
            <v-row class="ma-3">
                <v-btn
                class="mx-1"
                small
                @click="addRow" color="primary" outlined :disabled="saving"
                >Добавить расход
              </v-btn>
            </v-row>
            <v-row>
              <v-col>
               <v-textarea v-model = "comment" label="Комментарий" variant="outlined"></v-textarea>
              </v-col>
            </v-row>  

            <v-row class="ma-5" >
              <v-file-input @change="vistaPrevia" v-model="image" label="Выберите изображение" multiple ></v-file-input>
            </v-row>

            <v-row v-for="(u,index) in url" :key="index">
              <v-col cols="1">
                <div>{{index}}</div>
              </v-col>
              <v-col cols="11">
                <v-img :src="u.src" height="120px"/>
              </v-col>

            </v-row> 
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="mx-1"
            small
            outlined
            color="success"
            @click="saveDoc()"
            >Сохранить
          </v-btn>
          <v-btn
            class="mx-1"
            small
            @click="closeForm()"
            >Отмена
          </v-btn>
        </v-card-actions>

      </v-card>

      <v-row style="height: 350px;">
      </v-row> 
    </div>

  </template>
  
  <script>
  import ArendaService from '@/api/arenda-service'
  import { mapGetters, mapState } from "vuex"
  import { debounce } from 'lodash'
  


  export default {
    components: {  },
    props: {

    },
    data: function() {
      return {
        sums: [{sum:'',comment:'',id:0,dds:''}],
        carlist: [],
        docNum: '',
        docinfo: '',
        filterCar: '',
        selectedCar: 0,
        operationType: 0,
        doctype: '',
        comment: '',
        headers: [
          { text: '#' },
          { text: 'Сумма' },
          { text: 'Комментарий' },
          { text: '' },
        ],
        files: [],
        url: [],
        image: [],
        imagePreviewURL: null,
        avatarImage: [],
        dds: [{name:'Трансфер',id:'000000152',op:2},
          {name:'ЗП',id:'000000155',op:2},
          {name:'Тариф',id:'000000001',op:2},
          {name:'Доставка',id:'000000142',op:2},
          {name:'Повреждения',id:'000000071',op:2},
          {name:'ГСМ',id:'000000139',op:2},
          {name:'Мойка',id:'000000076',op:2},
          {name:'Транспортные',id:'000000121',op:2},
          {name:'Залог',id:'000000099',op:0},
          {name:'Прочее',id:'000000074_Прочее', op:2},
          {name:'Перегон',id:'000000121_Перегон', op:0},
          {name:'ГСМ',id:'000000075', op:0},
          {name:'ТО',id:'000000006_ТО', op:0},
          {name:'Шиномонтаж',id:'000000006_Шиномонтаж', op:0},
          {name:'Ремонт авто',id:'000000006_Ремонт авто', op:0},
          {name:'Мониторинг работы',id:'000000107_Мониторинг работы', op:0},
          {name:'Командировочные',id:'000000091', op:0},
          {name:'Аренда гаража',id:'000000148', op:0},
          {name:'Прочее',id:'000000074_Прочее', op:0},
          {name:'Интернет, связь',id:'000000081', op:1},
          {name:'Канцтовары',id:'000000132', op:1},
          {name:'Почтовые расходы',id:'000000126', op:1},
          {name:'Командировочные',id:'000000091', op:1},
          {name:'Мелкая техника',id:'000000131', op:1},
          {name:'Реклама',id:'000000124', op:1},
          {name:'Поддержка ПО',id:'000000128', op:1},
          {name:'Транспортные',id:'000000121',op:1},
          {name:'Спорт, корпоративы',id:'000000127', op:1},
          {name:'Прочее',id:'000000074_Прочее', op:1},
        ]
      }
    },
    computed: {
      ...mapGetters('doc',['doc','cars','saving','url_vars']),
      ...mapState('doc',['dataLoaded','menu_visible']),
      sum_opl: function(){
          if (this.sums)
            return this.sums.reduce((accum,item) => accum + Number(item.sum), 0) 
          else return 0
           },
      filteredCars() {
        if (this.filterCar.length < 3) {
          return [];
        }
        const filter = this.filterCar.toLowerCase();
        return this.cars.filter(car => car.code.toLowerCase().indexOf(filter) !== -1);
      },
      filteredDDS() {
        return this.dds.filter(dds => (dds.op == this.operationType) && (this.doctype != 'Трансфер' || this.doctype == 'Трансфер' && dds.name == 'Трансфер'));
      },
      showCarResults() {
          return this.filterCar.length >= 3;
      },
      
    },
    created: async function() {
      if (this.url_vars['optype']) {
          this.operationType = Number(this.url_vars['optype']) 
        }
        await this.$store.dispatch('doc/loadCars', true)
        this.carlist = this.cars
        console.log(' RKO: created ')
        this.$store.commit('doc/dataLoaded', true)
    },
    methods: {
      addRow() {
          this.sums.push({
            id: this.sums.length + 1,
            sum: '',
            comment: '',
          });
        },
      removeRow(index) {
          this.sums.splice(index, 1);
        },

      async closeForm() {  
        console.log('form closed')   
        this.TWA.close()
      },
      async saveDoc() {
        if (this.sum_opl==0)
          { this.TWA.showAlert('Не заполнены суммы')
            return
          }
        if (this.operationType==0){

          if  (this.filteredCars.length==0 || (this.filteredCars.length < this.selectedCar))
            { console.log('не выбран автомобиль ')
              this.TWA.showAlert('Не выбран автомобиль')
              return
            }
          if (this.filteredCars.length==1) {
            this.selectedCar = 0
          }            
          console.log('save for car', this.filteredCars.length, this.selectedCar, this.filteredCars[this.selectedCar],(this.operationType==0), (this.filteredCars.length < this.selectedCar))
        }
        if (this.operationType==2 && !this.docNum)
          { console.log('не выбран документ ')
            this.TWA.showAlert('Не заполнен номер документа')
            return
          }
        var data = {
          comment: this.comment,
          operation_type: this.operationType,
          docnum: this.docNum,
          ts: (this.operationType==0) ? this.filteredCars[this.selectedCar]:'---',
          sums: this.sums.map((item) => ( {sum_opl: Number(item.sum), comment: item.comment, dds: item.dds})),
          test:  (this.url_vars['test'])
          }
        try { 
          data.tg = this.TWA.initDataUnsafe
          data.user = this.TWA.initDataUnsafe.user.username
          data.userid = this.TWA.initDataUnsafe.user.id
        } catch(err) { 
          console.log('no tg')
          data.tg = {user:{id:'128283566'} }
          data.user = 'olegkrt'
          data.userid = '128283566'
        }
        console.log('save',JSON.stringify(data) )
        this.$store.commit('doc/setSaving', true) 
        const response = await ArendaService.saveNewRKO_other(data)
        console.log(JSON.stringify(response) )
        if (response.data.result == false) {
          this.$store.commit('doc/setError', 'Ошибка при сохранении документа') 
          return  
        }

        console.log('form RKO saved',response.data.id) 
        if (this.files.length>0) {
          for (let i=0; i<this.files.length; i++ ) {
              console.log('saving file',i)
              const r_photo = await ArendaService.sendPhoto(response.data.id, {doctype:'rko', data:this.files[i], ...data})
              console.log('file',i,JSON.stringify(r_photo))
            }        
        }
        this.$store.commit('doc/setSaved', true) 

        this.TWA.showAlert('Документ сохранен');
        this.TWA.close()
      },
      searchDocs: debounce(function() {
         if (this.docNum.length >= 5) {
           console.log(`Search for ${this.docNum}`)
           this.loadDocinfo()
         } else {this.docinfo = '---'}
       },500), // время задержки в миллисекундах
       
      async loadDocinfo() {
          var payload = {operationtype:'transfer'}
          try { 
            payload.tg = this.TWA.initDataUnsafe
            payload.user = this.TWA.initDataUnsafe.user.username
            payload.userid = this.TWA.initDataUnsafe.user.id
          } catch(err) { 
            console.log('no tg')
            payload.user = 'notg'
          }
          const response = await ArendaService.docinfo(this.docNum, payload)
          console.log('result '+response.result)
           if (!response.id) {
             this.docinfo = 'не найден документ '+this.docNum
           } else {
             this.docinfo = response.docinfo
             this.doctype = response.type

           }

       }, 
        vistaPrevia() {
            this.image.forEach(element => {
                this.files.push(element)
                this.url.unshift({'src':URL.createObjectURL(element)})
            });
          },
      }
    }
  
  </script>
  
  <style scoped>
/*
bg_color            .
secondary_bg_color  var(--tg-theme-secondary-bg-color)
link_color          var(--tg-theme-link-color).
*/
#main {
  background-color: var(--tg-theme-bg-color, white);
  color: var(--tg-theme-text-color, black);
  word-wrap: break-word;
}
b {
  color: var(--tg-theme-hint-color, black);
}
h3 {
  color: var(--tg-theme-text-color, black);
}
button22 {
  background-color: var(--tg-theme-button-color, #008CBA);
  border: 5px;
  color: var(--tg-theme-button-text-color, black);
  padding: 15px;
  margin: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
}
</style>