<template>
    <request v-if="current_request_id != ''" ></request>
    <div class="page-wrapper" id="main" 
         v-show="listvisible">
        <v-row class="ma-3">
            <v-btn
            class="mx-1"
            small
            @click="closeForm()"
            >Закрыть
          </v-btn>
          <v-icon  />
        </v-row>
        <v-row class = "ma-3">
            <v-switch
                v-model="showCompleted"
                color="primary"
                hide-details
                inset
                label="Показывать завершенные"
            ></v-switch>
        </v-row>
        <v-row class = "scrollable-content ma-3">
            <v-list select-strategy="classic"
                lines="three"
            >
            <v-list-item
                v-for="item in filteredRequests"
                :key="item.docnum"
                
                :subtitle="item.description"
                @click="openRequestForm(item.id)"
            >
                <template v-slot:title>
                    <v-row no-gutters>

                        <v-col cols="7">
                            <div align="left" class="text-subtitle-2">{{ item.docnum }} от {{ item.date }} (фото:{{ item.photo_count }}) </div>
                        </v-col>
                        <v-col cols="5">
                            <div align="right" class="text-primary">{{ item.sum }}</div>
                        </v-col>
                        
                    </v-row>
                    <div> ответственный: {{ item.sotr }} </div>
                </template>

                <template v-slot:prepend>
                    <v-icon :color="item.accepted === true ? 'green' : 'red'" v-if="item.completed">
                              {{ item.accepted === true ? 'mdi-check-circle' : 'mdi-alert-circle' }}
                    </v-icon>              
                    <v-icon v-if="item.completed==false">
                            {{  'mdi-help'}} 
                    </v-icon>              
                </template>


            </v-list-item>
            </v-list>
        </v-row>

    </div>
  </template>
  
  <script>
  //import ArendaService from '@/api/arenda-service'
  import request from '@/views/form_Request.vue'
  import { mapGetters } from "vuex"

  export default {
    components: {request},
    data: function() {
      return {
        //requests: [],
        showCompleted: false
     }
    },
    computed: {
      ...mapGetters('doc',['dataLoaded','url_vars','current_request','current_request_id','tg','requests']),
      dataLoading(){
        return this.dataLoaded == false
      },
      filteredRequests() {
        return this.requests.filter(item => this.showCompleted || item.completed === this.showCompleted)
      },
      listvisible(){
        return this.current_request_id === ''
      }
    },
    created: async function() {
        await this.loadRequests()
   },
    methods: {
      async loadRequests() {
        this.$store.commit('doc/dataLoaded', false)
        await this.$store.dispatch('doc/loadRequests', {}) 

//        var payload = {type:'all',...this.tg}
//        const response = await ArendaService.getRequests('0',payload)
//        console.log('requests loaded ')
//        if (response.data.error){
//            this.$store.commit('doc/setError', response.data.error)    
//        } else
//            this.requests = response.data.requests
         
//        this.$store.commit('doc/dataLoaded', true)
      },
      openRequestForm(id) {
         // Открываем форму передавая id запроса
         console.log('request ',id)
         this.$store.commit('doc/setCurrentRequestID', id) 

     },

      async closeForm() {  
        this.TWA.close()
      },
      formatDate(date) {
        const day = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()
        return `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year}`
        }

      }
    }
  
  </script>
  
<style>
.scrollable-content {
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
}
tr {
    white-space: pre-wrap;
}


</style>
