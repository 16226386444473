<template>
    <div class="page-wrapper mx-5" id="main">
        <v-row class="ma-3">
            <v-btn
            class="mx-1"
            small
            @click="closeForm()"
            >Закрыть
          </v-btn>
        </v-row>
        <v-row >
            <v-item-group v-show="false"
                v-model="period" 
                mandatory :disabled="dataLoading">
                <v-item                  
                  v-for="(day, index) in periodDates"
                    :key="index" :value="day"
                  v-slot="{ isSelected }"
                  selected-class="bg-primary"
                  >
                    <v-btn
                    class="ma-2" :disabled="dataLoading"
                    :color="isSelected ? 'primary' : undefined"
                    @click="toggle(day)">{{ day }}</v-btn>

                </v-item>
            </v-item-group>
        </v-row>
        <v-row >
          <v-text-field type="date" 
                  class="my-5"
                  v-model = "reportPeriod" label="Период отчета" variant="outlined"
                  locale = "ru-ru"
                  @change="onchangePeriod()"
                  ></v-text-field>
        </v-row>
        <v-row v-if="showCities" >
            <v-item-group
                v-model="city" 
                mandatory :disabled="dataLoading">
                <v-item                  
                  v-for="(elem, index) in cities"
                    :key="index" :value="elem"
                  v-slot="{ isSelected }"
                  selected-class="bg-primary"
                  >
                    <v-btn
                    class="ma-2" :disabled="dataLoading"
                    :color="isSelected ? 'primary' : undefined"
                    @click="toggleCity(elem)">{{ elem }}</v-btn>

                </v-item>
            </v-item-group>
        </v-row>
        <v-row class = "scrollable-content ma-1">
            <div
             v-html="htmlContent"></div>
        </v-row>

    </div>
  </template>
  
  <script>
  import ArendaService from '@/api/arenda-service'
  import { mapGetters } from "vuex"

  export default {
    components: {  },
    props: {
      reportname: String
    },
    data: function() {
      return {
        name: '',
        htmlContent: '',
        reportPeriod: '',
        period: '',
        periodDates: [],
        city: '',
        cities: ['Оренбург','Уфа','Казань','Екатеринбург','Челябинск','Сочи','Самара','Тюмень'],
        showCities: false

     }
    },
    computed: {
      ...mapGetters('doc',['dataLoaded','url_vars']),
      dataLoading(){
        return this.dataLoaded == false
      }
    },
    created: async function() {

        var date = new Date()
        this.reportPeriod = this.formatDate(date,1)

        // Заполняем массив значениями дат за последние 3 дня.
        for (let i = 0; i < 3; i++) {
//          this.reportPeriod = this.formatDate(date,0)
            date.setDate(date.getDate() - (2-i))
            this.periodDates.push(this.formatDate(date,0))
        }
        this.period = this.periodDates[2]

        try {
            this.showCities = this.TWA.initDataUnsafe.user.id=='128283566' || this.TWA.initDataUnsafe.user.id==435588563
        } catch(err){
            this.showCities = false
        }
        await this.loadReport()
   },
    methods: {
      async loadReport() {
        this.$store.commit('doc/dataLoaded', false)
//        var date = new Date(this.reportPeriod)
        var payload = {type:'cassa',
            period: this.reportPeriod,//this.formatDate(date),
            city: this.city}
          try { 
            payload.tg = this.TWA.initDataUnsafe
            payload.user = this.TWA.initDataUnsafe.user.username
            payload.userid = this.TWA.initDataUnsafe.user.id
          } catch(err) { 
            console.log('no tg')
            payload.user = 'olegkrt'
          } 
        const response = await ArendaService.getReport(payload.type, payload)
        console.log('report loaded ')
        if (response.data.error){
            this.$store.commit('doc/setError', response.data.error)    
        } else
            this.htmlContent = response.data
         
        this.$store.commit('doc/dataLoaded', true)
      },
      async toggle(date) {
        console.log('toggle',date)
        this.period = date
        await this.loadReport() 
      },
      async onchangePeriod() {
        console.log('change',this.reportPeriod)
        await this.loadReport() 
      },     
      async toggleCity(city) {
        console.log('toggle city', city)
        this.city = city
        await this.loadReport() 
      },     
      async closeForm() {  
        this.TWA.close()
      },
      formatDate(date, mode) {
        const day = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()
        if (mode==0)
          return `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year}`
        else
          return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`
        }

      }
    }
  
  </script>
  
<style>
.scrollable-content {
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
}
tr {
    white-space: pre-wrap;
}


</style>
