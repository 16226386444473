<template>
    <div class="page-wrapper" id="main" >
      <v-card  v-if="dataLoaded==true && isError != true" :disabled="docOutSaved || !dataLoaded || saving"
        v-bind:style="docOutSaved?'background:#c5c5c5' : ''"
        >
        <v-card-title class="mb-5">Выдача</v-card-title>
        <v-card-text>
            <v-row>
                <v-text-field type="date" 
                  v-model = "localdoc.date_out" label="Дата фактической выдачи" variant="outlined"
                  locale = "ru-ru"
                  ></v-text-field>
            </v-row>   
            <v-row>
              <v-col cols="8">
                <v-text-field type="time" 
                  v-model = "localdoc.time_out" label="Время фактической выдачи" variant="outlined"
                  ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-btn
                  class="mx-1"
                  small
                  @click="setCurrentTime()"
                  >Текущее
                </v-btn>
              </v-col>
            </v-row>  
            <v-row>
                <v-text-field type="number" pattern="[0-9]*"
                  v-model = "localdoc.odo_out" label="Одометр (км)" variant="outlined"
                  min="odo_min"
                  ></v-text-field>
            </v-row>  
            <v-row>
                <v-text-field type="number" pattern="[0-9]*"
                  v-model = "localdoc.gsm_out" label="Остаток топлива (км)" variant="outlined"
                  ></v-text-field>
            </v-row>  
            <v-row class="savedBackground">
                <v-text-field type="number" pattern="[0-9]*" class="forIOS"
                  v-model = "localdoc.sum_dostavka_out" label="Сумма доставка (выдача)" variant="outlined"
                  ></v-text-field>
            </v-row>  
            <v-row>
                <v-text-field class="forIOS" id="my-input"
                  v-model = "localdoc.territory" label="Территория использования" variant="outlined"
                  ></v-text-field>
            </v-row>             
            <v-row>
              <v-col>
               <v-textarea class="forIOS" v-model = "localdoc.comment_out" label="Комментарий выдачи" variant="outlined"></v-textarea>
              </v-col>
            </v-row>  
            
        </v-card-text>
        <v-card-actions v-show="!docOutSaved">
          <v-btn
            class="mx-1"
            small
            outlined
            color="success"
            @click="saveDoc()"
            >Провести
          </v-btn>
          <v-btn
            class="mx-1"
            small
            @click="closeForm()"
            >Отмена
          </v-btn>
        </v-card-actions>

      </v-card>
      <v-progress-linear  v-show="(saving==true)"
         color="dark-blue" indeterminate></v-progress-linear>

    </div>
  </template>
  
  <script>
  import { mapGetters, mapState } from "vuex"
 // import ArendaService from '@/api/arenda-service'


  export default {
    components: {  },
    props: {
      arendaid: String,
    },
    data: function() {
      return {
        localdoc: {},
        testcolor: false
      }
    },
    computed: {
        ...mapGetters('doc',['doc','saving']),
        ...mapState('doc',['dataLoaded','isError','ErrorText','menu_visible','docOutSaved']),
    },
    created: async function() {
        console.log('Arenda out created ') 
    
  
        if (this.doc.status != 'Бронь' && this.doc.status !='Готов к выдаче')
          { 
            this.$store.commit('doc/setError', 'Выдача невозможна, документ в статусе '+this.doc.status) 
            this.$store.commit('doc/setShowMenu', true)
          }
        Object.assign(this.localdoc, this.doc)
        this.localdoc.odo_out = '' //(this.localdoc.odo_out)==0 ? '' : this.localdoc.odo_out
        this.localdoc.gsm_out = '' //(this.localdoc.gsm_out)==0 ? '' : this.localdoc.gsm_out
        this.localdoc.sum_dostavka_out = (this.localdoc.sum_dostavka_out)==0 ? '' : this.localdoc.sum_dostavka_out

    },
    mounted: function() {
    },
    methods: {
      async closeForm() {  
        console.log('form closed')  
        this.$store.commit('doc/setMode', 'docinfo')
        //this.TWA.close()
      },
      setCurrentTime(){
        const now = new Date();
        console.log('date now',now)
        let minutes = Math.round(now.getMinutes() / 5) * 5;
        minutes = (minutes==60) ? 0 : minutes
        now.setMinutes(minutes);
        const time_out = `${('0' +now.getHours()).slice(-2)}:${('0' + minutes).slice(-2)}`
        console.log('time out',time_out)
        //console.log('date out',now.toISOString().split('T')[0],now.toISOString(),now.toLocaleDateString())
        const formattedDate = ''+ now.getFullYear() + '-' + String(now.getMonth() + 1).padStart(2, '0') + '-' +String(now.getDate()).padStart(2, '0')
        console.log('date out formatted',formattedDate)

        this.localdoc.time_out = time_out
        this.localdoc.date_out = formattedDate
  
      },
      async saveDoc() {

        if (this.localdoc.odo_out==0 )
          { console.log('не заполнен пробег ')
            this.TWA.showAlert('Не заполнен пробег.')
            return
          }
        var data = {
          id:       this.arendaid,
          comment_out: this.localdoc.comment_out,
          odo_out:  this.localdoc.odo_out,
          gsm_out:  this.localdoc.gsm_out,
          date_out: this.localdoc.date_out,
          time_out: this.localdoc.time_out,
          sum_dostavka_out: this.localdoc.sum_dostavka_out,
          territory: this.localdoc.territory,   
          }
        try { 
          data.tg = this.TWA.initDataUnsafe
          data.user = this.TWA.initDataUnsafe.user.username
        } catch(err) { 
          console.log('no tg')
        }
      
        const response = await this.$store.dispatch('doc/saveArendaOut', data) 
        this.$store.commit('doc/setError', '') 
        //const response = await ArendaService.saveArendaOut(this.arendaid, data)
        console.log('response',JSON.stringify(response))
        console.log('form saved') 
        try {
          this.TWA.showAlert('Документ сохранен')
        } catch(err) { 
          console.log('no tg')
        }
        this.$store.commit('doc/setdocOutSaved', true)
        this.$store.commit('doc/setMode', 'newpko')
      },

      }
    }
  
  </script>
  
  <style scoped>
/*
bg_color            .
secondary_bg_color  var(--tg-theme-secondary-bg-color)
link_color          var(--tg-theme-link-color).
*/
#main {
  background-color: var(--tg-theme-bg-color, rgb(160, 155, 155));
  color: var(--tg-theme-text-color, black);
  word-wrap: break-word;
}

.forIOS:focus {
  scroll-padding-top: 50px; /* здесь нужно указать высоту отступа */
}

b {
  color: var(--tg-theme-hint-color, black);
}
h3 {
  color: var(--tg-theme-text-color, black);
}
button22 {
  background-color: var(--tg-theme-button-color, #008CBA);
  border: 5px;
  color: var(--tg-theme-button-text-color, black);
  padding: 15px;
  margin: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
}
#savedBackground2 {
  background: #9b9b9b important;
  background-color: var(--tg-theme-button-color, #008CBA);
}
</style>