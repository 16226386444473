import { createApp } from 'vue'
import App from './App.vue'
//import router from './router'
//import Vuex from 'vuex'
import Axios from 'axios'
import store from './store'
//import { useStore } from 'vuex'


// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    }
  }, 
})
Axios.defaults.baseURL = 'https://1c.dacarrent.ru'

const app = createApp(App).use(vuetify)
app.use(store)

app.config.globalProperties.TWA = window.Telegram.WebApp

app.mount('#app')



//создание проекта
//https://forproger.ru/article/osnovy-vuejs-sozdanie-i-nastrojka-proekta