<template>
    <div class="page-wrapper" id="main">
      <v-card  v-if="dataLoaded==true" :disabled="saving">
        <v-card-title>Заявка на расход ДС</v-card-title>
        <v-card-actions>
          <v-btn v-if="current_request.completed == true && current_request.accepted==true"
            class="mx-1"
            small
            outlined
            color="success"
            >Утверждено
          </v-btn>
          <v-btn v-if="current_request.completed == true && current_request.accepted==false"
            class="mx-1"
            small
            outlined
            color="red"
            >Отклонено
          </v-btn>

          <v-btn v-if="current_request.completed != true"
            class="mx-1"
            small
            outlined
            color="success"
            @click="saveDoc(true)"
            >Утвердить
          </v-btn>
          <v-btn v-if="current_request.completed != true"
            class="mx-1"
            small
            outlined
            color="red"
            @click="saveDoc(false)"
            >Отклонить
          </v-btn>
          <v-btn
            class="mx-1 right"
            small
            @click="closeForm()"
            >Закрыть
          </v-btn>
        </v-card-actions>

        <v-card-text>
            <v-row>
                <v-textarea v-model = "current_request.description" label="Цель" variant="outlined" readonly></v-textarea>
            </v-row>  
            <v-row>
                <p class="text-h7">Заявитель: {{ current_request.sotr }} </p>
            </v-row>  
            <v-row>
                <p class="text-h7">{{ current_request.osn }} </p>
            </v-row>  
            <v-row justify="end">
                <v-col class="itemValue" cols="9">
                <p class="font-weight-bold text-h6">Сумма расходы: {{ current_request.sum }} </p>
                </v-col>   
            </v-row>  
            <v-row>
                <v-col>
                    <v-textarea v-model = "comment" label="Комментарий утверждающего" variant="outlined" :readonly="current_request.completed"></v-textarea>
                </v-col>
            </v-row>  
            <v-row justify="end" v-if="current_request.completed">
                <p class="text-h7">Утвердил: {{ current_request.accepted_by }} </p>  
            </v-row>  

            <v-row v-for="(u,index) in url" :key="index">
              <v-col cols="1">
                <div>{{index}}</div>
              </v-col>
              <v-col cols="11">
                <v-img  :src="u.src" height="200px"/>
              </v-col>

            </v-row> 
        </v-card-text>

      </v-card>

      <v-row style="height: 250px;">
      </v-row> 
    </div>

  </template>
  
  <script>
  import ArendaService from '@/api/arenda-service'
  import { mapGetters } from "vuex"
  
  export default {
    components: {  },
    props: {
      
    },
    data: function() {
      return {
        files: [],
        url: [],
        comment: '',
        completed: false
      }
    },
    computed: {
      ...mapGetters('doc',['saving','url_vars','current_request','current_request_id','dataLoaded','tg']),
      sum_opl: function(){
          return 0
        },
      
    },
    mounted: async function() {
        console.log('reqest mounted 1',this.current_request_id)
        this.$store.commit('doc/dataLoaded', false)
        await this.$store.dispatch('doc/loadRequest', this.current_request_id) 
        console.log('reqests mounted 2',JSON.stringify(this.current_request)) 
        this.comment = this.current_request.accept

        for (let i=0; i<this.current_request.photo.length; i++) {
            let elem = this.current_request.photo[i]
            let fileobject = await ArendaService.getPhoto(elem.id, this.tg)
            console.log('photo loaded',typeof(fileobject))
            if (fileobject){
                this.url.unshift({'src':URL.createObjectURL(fileobject),
                  'file':fileobject})

            }
        }
    },

    methods: {

      async closeForm() {  
        console.log('form closed')  
        this.$store.commit('doc/setCurrentRequestID', '')  
      },
      async saveDoc(result) {
        const data = {
            id: this.current_request_id,
            comment: this.comment,
            accepted: result,
            ...this.tg
          }
        console.log('save',JSON.stringify(data) )
        this.$store.commit('doc/setSaving', true) 
        const response = await ArendaService.saveRequestConfirmation(this.current_request_id,data)
        console.log(JSON.stringify(response) )
        if (response.data.result == false) {
          this.$store.commit('doc/setError', 'Ошибка при сохранении документа') 
          return  
        }

        this.$store.commit('doc/setSaved', true) 
        this.$store.commit('doc/setCurrentRequestID', '') 

        this.TWA.showAlert('Документ сохранен');

      },
      }
    }
  
  </script>
  
  <style scoped>
/*
bg_color            .
secondary_bg_color  var(--tg-theme-secondary-bg-color)
link_color          var(--tg-theme-link-color).
*/
#main {
  background-color: var(--tg-theme-bg-color, white);
  color: var(--tg-theme-text-color, black);
  word-wrap: break-word;
}
b {
  color: var(--tg-theme-hint-color, black);
}
h3 {
  color: var(--tg-theme-text-color, black);
}
button22 {
  background-color: var(--tg-theme-button-color, #008CBA);
  border: 5px;
  color: var(--tg-theme-button-text-color, black);
  padding: 15px;
  margin: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
}
</style>