<template>
    <div class="page-wrapper" id="arenda">

        <v-row v-if="doc.docnum !=''"
            class="ma-5">
            <div class="bold">№{{ doc.docnum }} - {{ doc.status }} -</div>
            <div> {{ doc.docinfo }}</div>
        </v-row>

        <v-row v-if="doc.status=='Готов к выдаче' && (menu_visible == true || command == 'docinfo')"
            class="ma-3" >
            <v-btn @click="printArenda()"
                    >Печатная форма договора/расписки
            </v-btn>
        </v-row>
 
        <arendaout v-if ="dataLoaded && (command == 'arenda_out' || docOutSaved)"
          v-bind:arendaid="arendaid" ></arendaout>   
        <arendain v-if ="dataLoaded && (command == 'arenda_in' || docInSaved)"
          v-bind:arendaid="arendaid" ></arendain>   
        <discounts v-if ="dataLoaded && (command == 'discounts' || discountsSaved)"
          v-bind:arendaid="arendaid" ></discounts>   
        <newpko2 v-if ="dataLoaded && (command == 'newpko' || pkoSaved)"
            v-bind:arendaid="arendaid"></newpko2>
        <div v-if="pkoSaved" class="ma-3">Внесено ПКО: {{ sumPKO }} р.</div>
        <newrko v-if ="dataLoaded && (command == 'newrko' || rkoSaved)"
          v-bind:arendaid="arendaid" v-bind:rkotype="'out'"></newrko>   
        <div v-if ="menu_visible == true || command == 'docinfo'">
            <v-row class="ma-3">
                <div> {{ sum_opl }}</div>
            </v-row>
            <div v-for="elem in opl_sums"
                        :key="elem">
                <v-row class="ma-3">
                        <div >{{ elem.item_name }}: {{ elem.sum_opl }}</div>
                </v-row>
            </div>
            <v-row class="ma-3" v-if="doc.status=='Штрафы 2 месяца' && false">
                <v-btn
                    @click="showDiscounts()"
                    >Ввести скидки
               </v-btn>
            </v-row>
            <v-row class="ma-3" >
                <v-btn v-if="false"
                    @click="showPKO()"
                    >Ввести ПKO
               </v-btn>
               <div v-if="sumPKO" class="ma-3">Внесено ПКО: {{ sumPKO }} р.</div>
            </v-row>
            <v-row class="ma-3" >
                <v-btn v-if="false"
                @click="showRKO()"
                >Ввести РKO
                </v-btn>
                <div v-if="sumRKO" class="ma-3">Внесено РКО: {{ sumRKO }} р.</div>
            </v-row>
            <v-row class="ma-3">
                <v-btn
                  @click="closeForm()"
                  >Закрыть форму
                </v-btn>
            </v-row>
       </div>
       <v-row style="height: 350px;">

       </v-row> 

    </div>
  </template>
  
<script>
import { mapGetters, mapState } from "vuex"

//import newpko from '@/views/form_PKO.vue'
import newpko2 from '@/views/form_PKO2.vue'
import newrko from '@/views/form_RKO.vue'
import arendaout from '@/views/form_Arenda_out.vue'
import arendain from '@/views/form_Arenda_in.vue'
import discounts from '@/views/form_Discounts.vue'
import ArendaService from '@/api/arenda-service'

  export default {
    components: { newpko2, newrko, arendaout, arendain, discounts },
    props: {
    },
    data: function() {
      return {
        initial_command: ''
      }
    },
    computed: {
        ...mapGetters('doc',['dataLoaded','saving','doc','command','arendaid','docInSaved','docOutSaved','pkoSaved','rkoSaved','discountsSaved']),
        ...mapState('doc',['isError','ErrorText','menu_visible']),
        sum_opl() {
            var res = 'Сумма к оплате: '+this.doc.sum_opl
            return res
       },
       opl_sums() {
            return this.doc.sums.filter(item => item.sum_opl != 0)
       },
       sumPKO(){
          var res = 0
          if (this.doc.docs) 
             res = this.doc.docs.reduce((accum,item) => accum + ((item.type === 'Приход')?Number(item.sum):0), 0) 
          return  res
       },
       sumRKO(){
          return this.doc.docs.reduce((accum,item) => accum + ((item.type === 'Расход')?Number(item.sum):0), 0)  
       }
    },
    created: async function() {
        
        await this.$store.dispatch('doc/loadData', {arendaid: this.arendaid}) 

        //const response = await ArendaService.docinfo(this.arendaid)
        //useObjData().setdoc(response)
        //this.globalobj.dataLoaded = true
    },
    mounted: function() {
      //this.doc = {}
    },
    methods: {
      async closeForm() {  
        console.log('form closed')  
        ArendaService.sendInfoToTelegram(this.doc.id,{id:this.doc.id, regime: ((this.doc.status=='Штрафы 2 месяца')?'ВОЗВРАТ':'ВЫДАЧА')+',Оплаты'})
        this.TWA.close()
      },
      showPKO() {  
        this.$store.commit('doc/setMode', 'newpko')
      },
      showRKO() {  
        this.$store.commit('doc/setMode', 'newrko')
      },
      showDiscounts() {  
        this.$store.commit('doc/setMode', 'discounts')
      },
      async printArenda() {
        console.log('print')
        const data = {
                id: this.arendaid,
            }
        try { 
          data.tg = this.TWA.initDataUnsafe
          data.user = this.TWA.initDataUnsafe.user.username
        } catch(err) { 
          console.log('no tg')
        }
        ArendaService.printArenda(data.id, data)
        this.TWA.showAlert('Печатная форма будет отправлена в чат Telegram в течении нескольких секунд')
        }
      }
    }
  
  </script>
  
