<template>
    <div class="page-wrapper" id="main">
      <v-card  v-if="dataLoaded==true && isError != true" :disabled="docInSaved || !dataLoaded || saving"
       v-bind:style="docInSaved?'background:#c5c5c5' : ''">
        <v-card-title class="mb-5">Прием</v-card-title>
        <v-card-text>
            <v-row v-if="false">
              <v-col class="itemValue">
                {{ docinfo }} 
              </v-col>             
            </v-row>  
            <v-row>
                <v-text-field type="date" 
                  v-model = "localdoc.date_in" label="Дата фактического возврата" variant="outlined"
                  locale = "ru-ru"
                  ></v-text-field>
            </v-row>   
            <v-row>
              <v-col cols="8">
                <v-text-field type="time" 
                  v-model = "localdoc.time_in" label="Время фактического возврата" variant="outlined"
                  ></v-text-field>
                </v-col>
              <v-col cols="4">
                <v-btn
                  class="mx-1"
                  small
                  @click="setCurrentTime()"
                  >Текущее
                </v-btn>
              </v-col>
            </v-row>  
            <v-row>
                <v-text-field type="number" pattern="[0-9]*"
                  v-model = "localdoc.odo_in" label="Одометр (км)" variant="outlined"
                  min="odo_min"
                  ></v-text-field>
            </v-row>  
            <v-row>
                <v-text-field type="number" pattern="[0-9]*"
                  v-model = "localdoc.gsm_in" label="Остаток топлива (км)" variant="outlined"
                  ></v-text-field>
            </v-row>  
            <v-row>
                <v-text-field type="number" pattern="[0-9]*"
                  v-model = "localdoc.sum_dostavka_in" label="Сумма доставка (прием)" variant="outlined"
                  ></v-text-field>
            </v-row>  
            <v-row>
                <v-text-field type="number" pattern="[0-9]*"
                  v-model = "localdoc.sum_wash" label="Сумма мойка" variant="outlined"
                  ></v-text-field>
            </v-row> 
            <v-row>
              <v-switch class="ml-5 block" color="indigo"
                  v-model = "localdoc.is_destruct" 
                  :label="`Есть повреждения: ${localdoc.is_destruct?'(да)':'(нет)'}`"
              ></v-switch>
              <v-textarea v-show="localdoc.is_destruct" 
                 v-model = "localdoc.destruct_info" label="Описание повреждений" variant="outlined"
                 class="ml-5 block"></v-textarea>

            </v-row> 
            <v-row>
                <v-switch class="ml-5" color="indigo"
                  v-model = "localdoc.needrepair" 
                  :label="`Требуется ремонт: ${localdoc.needrepair?'(да)':'(нет)'}`"
                  ></v-switch>
            </v-row>  
            <v-row>
              <v-col>
               <v-textarea v-model = "localdoc.comment_in" label="Комментарий приемки" variant="outlined"></v-textarea>
              </v-col>
            </v-row>  
        </v-card-text>
        <v-card-actions v-show="!docInSaved">
          <v-btn
            class="mx-1"
            small
            outlined
            color="success"
            @click="saveDoc()"
            >Провести
          </v-btn>
          <v-btn
            class="mx-1"
            small
            @click="closeForm()"
            >Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-progress-linear  v-show="(saving==true)"
        color="dark-blue" indeterminate></v-progress-linear>       
    </div>
  </template>
  
  <script>
  import { mapGetters, mapState } from "vuex"

  export default {
    components: {  },
    props: {
      arendaid: String,
    },
    data: function() {
      return {
        localdoc: {}
      }
    },
    computed: {
      ...mapGetters('doc',['doc','saving']),
      ...mapState('doc',['dataLoaded','isError','ErrorText','menu_visible','docInSaved']), 
    },
    created: async function() {
      console.log('Arenda in created ') 

      if ((this.doc.status != 'Передан в аренду') && (!this.docInSaved))
        { 
          this.$store.commit('doc/setError', 'Приемка невозможна, документ в статусе '+this.doc.status) 
          //this.$store.commit('doc/setShowMenu', true)
        }
      Object.assign(this.localdoc, this.doc)
      this.localdoc.odo_in = '' //(this.localdoc.odo_in)==0 ? '' : this.localdoc.odo_in
      this.localdoc.gsm_in = '' //(this.localdoc.gsm_in)==0 ? '' : this.localdoc.gsm_in
      this.localdoc.sum_dostavka_in = (this.localdoc.sum_dostavka_in)==0 ? '' : this.localdoc.sum_dostavka_in
      this.localdoc.sum_wash = (this.localdoc.sum_wash)==0 ? '' : this.localdoc.sum_wash
          
    }, 

    methods: {
      async closeForm() {  
        console.log('form closed')  
        this.$store.commit('doc/setMode', 'docinfo')
      },
      setCurrentTime(){
        const now = new Date();
        let minutes = Math.round(now.getMinutes() / 5) * 5;
        minutes = (minutes==60) ? 0 : minutes
        now.setMinutes(minutes);
        const cur_time = `${('0' +now.getHours()).slice(-2)}:${('0' + minutes).slice(-2)}`
        const formattedDate = ''+ now.getFullYear() + '-' + String(now.getMonth() + 1).padStart(2, '0') + '-' +String(now.getDate()).padStart(2, '0')
        console.log('date out formatted',formattedDate)

        this.localdoc.time_in = cur_time
        this.localdoc.date_in = formattedDate
      },
      async saveDoc() {

        if (this.localdoc.odo_in==0 )
          { console.log('не заполнен пробег ')
            this.TWA.showAlert('Не заполнен пробег.')
            return
          }
        const data = {
          id:this.arendaid,
          comment_in: this.localdoc.comment_in,
          odo_in: this.localdoc.odo_in,
          gsm_in: this.localdoc.gsm_in,
          date_in: this.localdoc.date_in,
          time_in: this.localdoc.time_in,
          needrepair: this.localdoc.needrepair,
          sum_dostavka_in: this.localdoc.sum_dostavka_in,
          sum_wash: this.localdoc.sum_wash,
          is_destruct: this.localdoc.is_destruct,
          destruct_info: this.localdoc.destruct_info,
          }
        try { 
          data.tg = this.TWA.initDataUnsafe
          data.user = this.TWA.initDataUnsafe.user.username
        } catch(err) { 
          console.log('no tg')
        }
        const response = await this.$store.dispatch('doc/saveArendaIn', data) 
        this.$store.commit('doc/setError', '') 
        console.log(JSON.stringify(response)) 
        console.log('form arenda in saved') 
        try {
//          this.TWA.showAlert('Документ сохранен')
        } catch(err) { 
          console.log('no tg')
        }
        this.$store.commit('doc/setdocInSaved', true)
        this.$store.commit('doc/setMode', 'discounts')
        //this.$store.commit('doc/setMode', 'docinfo')       
      }
      }
    }
  
  </script>
  
  <style scoped>
/*
bg_color            .
secondary_bg_color  var(--tg-theme-secondary-bg-color)
link_color          var(--tg-theme-link-color).
*/
#main {
  background-color: var(--tg-theme-bg-color, white);
  color: var(--tg-theme-text-color, black);
  word-wrap: break-word;
}
b {
  color: var(--tg-theme-hint-color, black);
}
h3 {
  color: var(--tg-theme-text-color, black);
}
button22 {
  background-color: var(--tg-theme-button-color, #008CBA);
  border: 5px;
  color: var(--tg-theme-button-text-color, black);
  padding: 15px;
  margin: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
}
.block {
  display: block;
  width: 100%;
}

</style>